import React from 'react'

interface Props {
    width?: string
    height?: string
}

const AwaitingInputIllustration = ({ width = '140', height = '140' }: Props) => (
    <svg width={width} height={height} viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M79.4267 390.347C79.0148 392.187 78.6906 394.795 77.8668 397.104C72.9985 410.727 68.0469 424.302 63.0384 437.838C61.6449 441.602 58.7792 443 56.2333 441.558C53.7005 440.125 63.8666 404.474 68.4369 392.043C69.0372 390.408 69.769 388.844 70.5314 387.328C70.9696 386.46 71.5349 385.654 72.1528 384.992C73.6338 383.397 75.334 382.28 77.2314 383.454C79.1813 384.664 79.269 387.218 79.4267 390.351V390.347Z"
            fill="#C19E9A"
        />
        <path
            d="M257.358 436.909C256.889 440.371 254.483 439.192 253.296 437.168C252.511 435.827 251.977 434.201 251.468 432.628C247.179 419.307 242.911 405.968 238.66 392.626C238.187 391.14 237.67 389.602 237.516 388.033C237.249 385.312 238.218 383.196 240.062 381.855C241.85 380.553 244.076 380.808 245.614 382.718C246.499 383.818 247.306 385.189 247.783 386.64C252.13 399.829 257.822 433.452 257.353 436.913L257.358 436.909Z"
            fill="#C19E9A"
        />
        <path
            d="M121.392 393.046C92.8876 393.765 62.9943 392.262 36.957 379.712C21.6948 373.082 13.023 356.957 7.04611 342.19C0.302352 325.39 0.346171 307.402 0 289.62C0.416281 272.211 -0.85447 249.959 15.9414 239.662C35.7082 229.176 54.3795 254.148 57.7009 215.355C60.5974 176.282 39.4985 100.501 78.8305 77.2597C108.557 63.5312 137.885 67.3346 174.562 66.9578C211.243 66.5766 243.064 74.6437 251.933 115.032C262.857 153.597 240.632 215.513 254.694 232.388C268.755 249.263 282.484 229.921 301.357 249.526C312.395 264.174 310.235 298.664 307.562 321.59C302.978 348.745 283.062 370.361 259.886 383.901C244.703 393.283 226.098 388.787 209.135 390.435C179.89 391.425 150.654 392.577 121.392 393.051V393.046Z"
            fill="#089188"
        />
        <path
            d="M436.021 299.002C435.811 300.443 435.688 301.631 435.46 302.792C434.014 310.149 431.911 317.335 429.856 324.539C425.391 340.209 421.083 355.927 416.631 371.601C412.543 385.991 408.095 400.276 402.666 414.228C401.22 417.948 399.581 421.59 397.351 424.924C396.755 425.818 396.063 426.686 395.274 427.409C393.819 428.745 392.088 428.662 390.638 427.317C389.249 426.029 388.394 424.39 387.685 422.668C385.572 417.541 384.267 412.191 383.25 406.757C381.379 396.762 379.416 386.789 377.606 376.785C376.449 370.387 375.49 363.955 374.451 357.535C373.391 350.958 372.278 344.389 371.305 337.803C370.591 332.983 370.008 328.137 369.478 323.29C368.969 318.668 368.575 314.027 368.185 309.391C368.032 307.581 368.023 305.758 367.957 303.944C367.948 303.752 367.913 303.528 367.997 303.379C368.141 303.125 368.369 302.915 368.558 302.687C368.755 302.871 369.075 303.024 369.136 303.248C369.5 304.597 369.907 305.947 370.113 307.327C371.121 314.106 372.028 320.898 373.058 327.668C374.087 334.412 375.205 341.138 376.318 347.869C377.483 354.945 378.658 362.018 379.885 369.086C381.217 376.75 382.575 384.405 383.995 392.051C385.257 398.852 386.519 405.657 387.983 412.414C388.662 415.547 389.779 418.588 390.791 421.642C391.098 422.563 391.698 423.391 392.207 424.232C392.61 424.898 393.105 424.92 393.517 424.241C394.634 422.383 395.975 420.613 396.777 418.628C399.091 412.861 401.317 407.051 403.337 401.175C408.376 386.508 412.346 371.522 416.588 356.615C420.803 341.804 425.316 327.076 429.777 312.336C430.697 309.29 431.902 306.324 433.107 303.379C433.642 302.069 434.501 300.895 435.237 299.672C435.355 299.479 435.601 299.361 436.03 299.015L436.021 299.002Z"
            fill="white"
        />
        <path
            d="M450.131 293.669C449.991 295.943 450.013 298.187 449.693 300.377C447.805 313.365 445.736 326.323 443.931 339.319C442.586 348.999 441.525 358.718 440.386 368.424C439.422 376.61 438.524 384.804 437.595 392.989C437.069 397.625 436.569 402.261 435.987 406.889C435.588 410.035 434.966 413.141 433.511 415.998C433.024 416.954 432.45 417.918 431.723 418.693C430.11 420.42 427.639 420.42 426.04 418.68C425.299 417.874 424.712 416.879 424.239 415.88C422.166 411.516 421.084 406.845 420.146 402.134C417.784 390.277 416.193 378.305 414.896 366.295C413.827 356.413 412.719 346.541 411.772 336.647C410.791 326.371 409.862 316.091 409.774 305.758C409.765 304.483 409.866 303.204 409.94 301.929C409.976 301.346 410.072 300.693 410.786 300.605C411.527 300.513 411.781 301.14 411.877 301.705C412.153 303.309 412.412 304.917 412.574 306.534C414.445 325.197 416.25 343.864 418.17 362.522C419.445 374.918 420.908 387.297 423.183 399.558C423.919 403.541 424.957 407.471 426.013 411.384C426.399 412.808 427.161 414.154 427.911 415.442C428.528 416.511 428.98 416.45 429.62 415.394C431.035 413.058 431.771 410.473 432.126 407.8C433.327 398.808 434.505 389.812 435.601 380.807C436.412 374.147 436.959 367.456 437.831 360.808C439.838 345.45 441.942 330.104 444.071 314.763C444.825 309.325 445.684 303.892 447.314 298.629C447.809 297.03 448.641 295.54 449.334 294.002C449.391 293.87 449.522 293.77 449.632 293.669C449.667 293.638 449.75 293.664 450.14 293.664L450.131 293.669Z"
            fill="#BDC4FC"
        />
        <path
            d="M357.266 418.321C358.085 417.208 359.058 416.174 359.697 414.969C361.45 411.674 363.203 408.37 364.706 404.956C372.243 387.832 378.689 370.273 385.218 352.746C390.108 339.618 395.112 326.533 400.147 313.462C401.729 309.356 403.552 305.338 405.322 301.311C405.835 300.15 406.536 299.067 407.193 297.976C407.421 297.604 407.723 297.205 408.091 297.008C408.433 296.824 409.033 296.714 409.283 296.898C409.52 297.069 409.616 297.727 409.489 298.068C407.885 302.433 406.242 306.784 404.586 311.131C400.822 321.034 397.036 330.924 393.28 340.831C387.273 356.672 381.37 372.561 375.24 388.353C371.997 396.709 368.685 405.053 364.526 413.019C363.654 414.688 362.739 416.34 361.674 417.887C360.964 418.921 360.061 419.89 359.062 420.648C357.274 422.006 355.526 421.559 354.395 419.622C353.164 417.515 352.546 415.192 352.305 412.795C351.665 406.376 350.969 399.961 350.557 393.524C348.602 363.039 349.299 332.515 349.264 301.999C349.264 299.484 349.584 296.964 349.829 294.453C349.864 294.081 350.32 293.752 350.579 293.402C350.89 293.704 351.433 293.976 351.477 294.313C351.674 295.763 351.814 297.231 351.823 298.695C351.946 314.097 352.029 329.5 352.147 344.907C352.231 355.677 352.248 366.448 352.489 377.214C352.634 383.568 353.081 389.917 353.537 396.258C353.896 401.289 354.417 406.31 354.974 411.327C355.167 413.063 355.657 414.767 356.082 416.467C356.231 417.068 356.586 417.62 356.849 418.194C356.985 418.238 357.121 418.282 357.257 418.325L357.266 418.321Z"
            fill="#BDC4FC"
        />
        <path
            d="M406.479 298.226C390.603 297.258 337.034 301.429 337.034 290.282C337.034 279.134 434.948 282.131 448.716 285.431C451.271 286.25 454.184 287.171 454.312 290.431C454.943 300.605 411.527 297.082 406.479 298.226Z"
            fill="#A06A63"
        />
        <path
            d="M238.218 178.959C244.283 157.374 272.423 175.309 300.375 161.914C308.688 157.931 326.912 157.611 328.696 169.03C328.297 174.065 325.287 177.886 320.537 179.586C312.513 182.46 306.457 187.802 300.608 193.7C283.672 213.638 232.153 200.549 238.218 178.959Z"
            fill="#448E86"
        />
        <path
            d="M498.652 172.978C506.101 189.835 480.708 175.226 470.507 188.696C465.564 195.221 458.518 219.869 440.184 215.022C430.435 212.446 430.759 195.186 437.227 180.944C446.81 159.832 490.638 154.837 498.652 172.982V172.978Z"
            fill="#448E86"
        />
        <path
            d="M424.909 85.9008C427.49 142.388 385.125 126.736 389.284 148.711C391.063 167.987 406.842 182.215 409.756 200.974C410.466 207.271 405.002 211.644 398.652 211.039C387.93 210.053 382.01 200.08 376.993 191.794C367.523 171.019 350.776 155.113 340.334 135.009C327.118 103.201 368.474 107.14 375.341 89.1039C382.439 71.8655 373.816 50.9288 387.43 36.3502C412.767 17.1048 427.073 67.1594 424.913 85.9008H424.909Z"
            fill="#448E86"
        />
        <path
            d="M382.058 157.948C381.585 139.658 390.932 130.031 404.441 121.92C431.749 103.371 396.199 69.1181 436.679 37.3317C452.383 22.9941 488.556 26.4953 485.835 53.0365C483.771 70.6737 470.454 81.5101 456.665 90.7691C441.008 102.096 453.444 121.478 436.784 135.351C424.422 146.437 440.684 161.791 434.518 175.484C420.374 210.5 383.43 183.88 382.058 157.948Z"
            fill="#B5EDE9"
        />
        <path
            d="M356.17 209.304C341.171 205.343 335.54 199.476 327.262 207.99C311.886 220.68 304.209 244.438 282.348 247.12C263.847 250.485 257.375 225.955 273.466 219.194C289.333 213.371 303.219 212.052 306.334 192.64C310.778 154.749 365.709 154.316 378.294 186.641C384.521 201.872 371.169 213.265 356.17 209.304Z"
            fill="#B5EDE9"
        />
        <path
            d="M315.918 119.488C306.361 103.498 301.532 94.6777 318.573 78.951C334.204 60.7486 369.872 65.6783 367.927 93.6392C365.723 112.455 336.999 96.4568 333.279 120.15C333.397 126.999 339.033 131.797 343.686 136.183C358.773 155.249 320.909 160.98 308.39 157.409C289.556 155.297 276.691 124.667 301.304 120.356C306.488 120.04 310.388 120.198 315.922 119.488H315.918Z"
            fill="#B5EDE9"
        />
        <path
            d="M390.091 233.856C403.92 233.856 414.682 232.76 417.32 241.283C419.958 249.806 413.012 288.257 389.683 288.257C366.354 288.257 360.193 250.135 361.586 242.852C362.98 235.569 376.261 233.856 390.086 233.856H390.091Z"
            fill="#CC4900"
        />
        <path
            d="M390.183 238.855C385.599 232.559 385.626 225.797 388.316 218.861C389.49 215.837 390.945 212.919 392.378 210.001C394.578 205.522 394.823 201.061 392.659 196.496C387.089 184.752 394.937 193.95 395.551 195.08C398.228 200.014 398.815 205.141 396.383 210.408C395.292 212.766 394.381 215.206 393.254 217.546C390.612 223.028 389.771 228.632 391.524 234.574C391.918 235.906 393.82 244.364 390.183 238.851V238.855Z"
            fill="#2A3858"
        />
        <path
            d="M152.175 129.553C225.848 129.553 225.848 161.563 240.05 246.296C246.579 285.264 202.957 296.819 155.141 299.181C107.326 301.543 61.2677 281.991 66.7013 241.664C81.5603 157.264 78.5018 129.553 152.175 129.553Z"
            fill="#6222C3"
        />
        <path
            d="M151.776 280.532C117.676 284.62 81.0171 264.222 71.7581 279.02C62.4991 293.818 81.1091 343.228 125.616 338.408C148.157 335.967 152.192 329.245 179.474 337.054C211.147 346.12 244.247 307.673 233.534 285.137C222.82 262.601 185.876 276.448 151.776 280.536V280.532Z"
            fill="#2A3858"
        />
        <path
            d="M225.936 466.105C235.186 453.617 200.696 441.597 201.279 415.946C200.288 406.025 204.574 414.237 202.449 404.43C198.829 395.027 183.948 397.713 180.565 405.25C174.133 426.077 177.016 431.313 180.793 452.6C182.323 461.483 190.968 466.618 199.504 467.026C208.237 468.218 218.083 470.496 225.94 466.105H225.936Z"
            fill="#213EAB"
        />
        <path
            d="M118.39 467.898C144.086 455.033 136.934 436.65 126.396 415.779C122.097 409.895 119.442 389.01 109.061 394.913C105.853 396.933 100.323 397.516 99.5742 402.467C98.8248 407.419 107.615 423.584 107.295 434.788C108.198 447.539 91.5465 479.387 118.386 467.898H118.39Z"
            fill="#213EAB"
        />
        <path
            d="M209.635 418.663C237.118 389.186 238.906 351.58 238.529 311.446C238.783 296.719 233.608 271.939 214.301 278.486C207.255 282.539 199.201 287.1 195.959 295.071C186.204 325.679 190.03 359.319 180.578 390.211C178.852 397.301 176.205 404.058 173.12 410.618C171.109 414.154 170.815 418.698 174.505 421.274C186.445 430.52 199.872 429.609 209.635 418.672V418.663Z"
            fill="#2A3858"
        />
        <path
            d="M93.2338 407.971C99.4561 425.805 130.712 423.132 124.932 397.305C120.638 367.285 114.512 348.083 120.209 318.422C124.354 292.039 88.4706 242.322 69.3874 283.49C54.1559 323.435 74.0892 369.761 93.2338 407.971Z"
            fill="#2A3858"
        />
        <path
            d="M67.8886 259.556C67.4986 253.982 83.0193 250.065 89.8594 248.969C96.6996 247.874 104.828 259.118 105.266 265.34C105.683 271.304 98.0054 289.445 84.404 283.49C79.9782 281.553 68.2961 265.362 67.8886 259.556Z"
            fill="#6222C3"
        />
        <path
            d="M195.823 266.19C195.433 260.616 204.39 250.065 211.23 248.969C218.07 247.874 232.622 262.22 233.056 268.442C233.477 274.406 225.721 284.519 219.139 285.133C205.327 286.417 196.226 271.996 195.818 266.194L195.823 266.19Z"
            fill="#6222C3"
        />
        <path
            d="M86.6037 273.131C85.552 262.43 122.342 257.597 123.521 269.542C124.696 281.487 87.6597 283.831 86.6037 273.131Z"
            fill="#F8B5A1"
        />
        <path
            d="M181.546 270.3C181.546 258.71 219.498 260.288 219.498 274.524C219.498 282.666 181.546 281.89 181.546 270.3Z"
            fill="#F8B5A1"
        />
        <path
            d="M115.976 389.83C116.217 389.291 116.559 388.629 116.813 387.946C118.745 382.718 119.508 377.267 120.529 371.855C122.544 361.163 122.387 350.423 121.58 339.666C121.011 332.081 120.279 324.504 119.666 316.924C119.258 311.88 118.96 306.823 118.579 301.78C118.5 300.763 118.32 299.747 118.049 298.761C117.943 298.379 117.453 298.095 117.133 297.766C116.769 298.081 116.199 298.344 116.081 298.726C115.731 299.839 115.463 300.991 115.332 302.148C114.543 309.247 114.859 316.341 115.577 323.431C116.835 335.818 117.378 348.237 117.212 360.673C117.111 368.271 115.783 375.746 114.289 383.196C113.956 384.861 113.64 386.53 113.404 388.209C113.312 388.861 113.391 389.558 113.535 390.207C113.754 391.162 114.385 391.315 115.152 390.662C115.406 390.448 115.617 390.189 115.963 389.83H115.976Z"
            fill="#3D5196"
        />
        <path
            d="M101.848 284.274C100.144 275.221 93.8951 229.579 93.7593 225.018C93.5928 219.409 97.2999 219.163 104.017 219.159C104.416 219.277 205.879 219.514 206.554 219.523C211.028 219.601 212.426 223.348 211.742 227.011C208.648 243.592 200.805 281.619 199.972 284.033C198.951 286.995 196.327 287.596 193.671 287.841C191.603 288.029 110.301 287.841 106.23 287.841C102.159 287.841 101.953 284.866 101.844 284.274H101.848Z"
            fill="#AAB5D2"
        />
        <path
            d="M123.123 94.9057C122.925 103.924 127.044 125.5 129.994 133.69C134.327 145.723 129.937 151.84 132.062 159.447C137.473 178.802 157.494 185.905 167.034 164.626C171.714 154.188 170.325 150.026 171.928 145.39C177.121 130.39 182.252 111.947 182.791 100.225C183.453 85.7738 181.455 57.9487 155.067 57.1336C132.815 56.45 122.715 76.1642 123.123 94.9013V94.9057Z"
            fill="#F9BCAA"
        />
        <path
            d="M186.309 101.509C188.443 59.0968 165.763 54.2591 151.329 54.3599C130.633 54.5045 112.807 79.7618 134.734 98.2885C141.688 103.981 156.236 117.863 162.831 110.334C168.383 113.31 173.948 116.158 179.912 117.109C184.592 118.248 186.634 103.735 186.314 101.514L186.309 101.509Z"
            fill="#A06A63"
        />
        <path
            d="M151.473 161.563C141.614 161.563 136.689 152.492 132.947 145.14C130.944 141.2 126.54 125.39 128.175 125.452C132.504 125.614 144.024 142.721 153.007 142.506C161.99 142.291 178.282 126.1 177.129 130.395C175.675 135.824 173.957 142.02 170.977 147.182C167.375 153.413 160.544 161.567 151.478 161.567L151.473 161.563Z"
            fill="#A06A63"
        />
        <path
            d="M152.026 153.277C148.739 153.233 145.751 151.634 145.751 150.231C145.751 148.829 149.283 148.742 152.105 148.746C154.927 148.755 158.669 149.526 158.494 150.591C158.323 151.66 155.063 153.312 152.021 153.273L152.026 153.277Z"
            fill="#F9BCAA"
        />
        <path
            d="M157.525 139.943C158.428 140.64 153.831 144.206 152.223 144.206C150.615 144.206 145.777 140.293 146.737 139.535"
            fill="#2A3858"
        />
        <path
            d="M125.2 112.03C122.005 110.711 113.531 89.2354 124.126 73.4824C134.722 57.7251 139.279 69.4117 135.585 76.8083C126.536 94.9275 128.399 113.349 125.204 112.03H125.2Z"
            fill="#A06A63"
        />
    </svg>
)

export default AwaitingInputIllustration
