import { getOIDCUser } from '@trr/app-shell-communication'

const isNotEmpty = (value: string) => value !== ''

export const getLoggedInUserKlientId = () => {
    const openIdCUser = getOIDCUser()
    return openIdCUser?.profile?.sub || ''
}

export const getLoggedInUserIdToken = () => {
    const openIdCUser = getOIDCUser()
    return openIdCUser?.id_token || ''
}

export const userIdTokenHasNotExpired = () => {
    const openIdCUser = getOIDCUser()
    const expiresAt = openIdCUser?.expires_at || 0
    const isValid = expiresAt * 1000 > Date.now()
    return isValid
}

export const userIsLoggedIn = () =>
    userIdTokenHasNotExpired() && isNotEmpty(getLoggedInUserKlientId()) && isNotEmpty(getLoggedInUserIdToken())
