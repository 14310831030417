import { defaultRaknesnurraEpiContent } from '@local/src/Features/RaknesnurraForm/RaknesnurraForm.content'
import { defaultLaddaUppStudieintygEpiContent } from '@local/src/Features/StudiestodStudieintyg/StudiestodStudieintyg.content'
import { IStudiestodEpiContent } from '@local/src/Types/StudiestodEpiContent'

export const epiFallbackContent: IStudiestodEpiContent = {
    studiestodRaknesnurra: defaultRaknesnurraEpiContent,
    studiestodList: {
        mittTrrAnsokanLink: 'epi:mittTrrAnsokanLink',
        mittTrrStudieintygLink: 'epi:mittTrrStudieintygLink',
        mittTrrReadMoreLink: 'epi:mittTrrReadMoreLink',
    },
    studiestodForm: {
        dinBakgrund: {
            dinBakgrundHeaderText: 'epi:dinBakgrundHeaderText',
            dinBakgrundLeadText: 'epi:dinBakgrundLeadText',
            kompletteringErfarenhetText: 'epi:kompletteringErfarenhetText',
            kompletteringErfarenhetPlaceholderText: 'epi:kompletteringErfarenhetPlaceholderText',
            utbildningsalternativText: 'epi:utbildningsalternativText',
            utbildningsalternativPlaceholderText: 'epi:utbildningsalternativPlaceholderText',
        },
        villkor: {
            forsakranModalDescription: 'epi:forsakranModalDescription',
            forsakranButtonCloseText: 'epi:forsakranButtonCloseText',
            forsakranModalTitle: 'epi:forsakranModalTitle',
            forsakranButtonText: 'epi:forsakranButtonText',
            villkorText: 'epi:villkorText',
        },
        malMedStudierna: {
            malMedStudiernaHeaderText: 'epi:malMedStudiernaHeaderText',
            malMedStudiernaLeadText: 'epi:malMedStudiernaLeadText',
            ansokanNamnText: 'epi:ansokanNamnText',
            ansokanNamnPlaceholderText: 'epi:ansokanNamnPlaceholderText',
            nyanstallningText: 'epi:nyanstallningText',
            nyanstallningPlaceholderText: 'epi:nyanstallningPlaceholderText',
            arbetsmarknadText: 'epi:arbetsmarknadText',
            arbetsmarknadPlaceholderText: 'epi:arbetsmarknadPlaceholderText',
            utbildningsforutsattningText: 'epi:utbildningsforutsattningText',
            utbildningsforutsattningVidareutbildningText: 'epi:utbildningsforutsattningVidareutbildningText',
            utbildningsforutsattningOmskolningText: 'epi:utbildningsforutsattningOmskolningText',
        },
        submitSaveButton: {
            draftButtonText: 'epi:draftButtonText',
            cancelButtonText: 'epi:cancelButtonText',
            updateButtonText: 'epi:updateButtonText',
            sendButtonText: 'epi:sendButtonText',
            mittTrrSubmitButtonLink: 'epi:mittTrrSubmitButtonLink',
        },
        utbildningar: {
            utbildningarHeaderText: 'epi:utbildningarHeaderText',
            utbildningarLeadText: 'epi:utbildningarLeadText',
            addUtbildningButtonText: 'epi:addUtbildningButtonText',
            utbildningsnamnText: 'epi:utbildningsnamnText',
            anordnareText: 'epi:anordnareText',
            utbildningstypText: 'epi:utbildningstypText',
            omfattningText: 'epi:omfattningText',
            studiestartText: 'epi:studiestartText',
            utbildningLangdText: 'epi:utbildningLangdText',
            utbildningLangdPlaceholderText: 'epi:utbildningLangdPlaceholderText',
            utbildningLangdEnhetText: 'epi:utbildningLangdEnhetText',
            kursavgiftText: 'epi:kursavgiftText',
            vilkenUtbildningText: 'epi:vilkenUtbildningText',
            removeButtonText: 'epi:removeButtonText',
            saveButtonText: 'epi:saveButtonText',
            csnInformationHeaderText: 'epi:csnInformationHeaderText',
            csnInformationLeadText: 'epi:csnInformationLeadText',
            studiemedelsberattigadUtbildningText: 'epi:studiemedelsberattigadUtbildningText',
            avserSokaCsnText: 'epi:avserSokaCsnText',
            kraverProvningText: 'epi:kraverProvningText',
            ejCsnAnledningText: 'epi:ejCsnAnledningText',
            ejCsnAnledningPlaceholderText: 'epi:ejCsnAnledningPlaceholderText',
            anordnarePlaceholderText: 'epi:anordnarePlaceholderText',
            utbildningsnamnPlaceholderText: 'epi:utbildningsnamnPlaceholderText',
            ansokanHeadingPlaceholder: 'epi:ansokanHeadingPlaceholder',
            omfattningTooltip: 'epi:omfattningTooltip',
            utbildningstypTooltip: 'epi:utbildningstypTooltip',
            utbildningsLangdTooltip: 'epi:utbildningsLangdTooltip',
        },
        valideringsmeddelanden: {
            hamtningsFelText: 'epi:hamtningsFelText',

            ansokanNamn: 'epi:ansokanNamn',
            kraverProvning: 'epi:kraverProvning',
            harIntygatUppgifter: 'epi:harIntygatUppgifter',
            motiveringNyanstallning: 'epi:motiveringNyanstallning',
            motiveringArbetsmarknad: 'epi:motiveringArbetsmarknad',
            motiveringKompletteringErfarenhet: 'epi:motiveringKompletteringErfarenhet',
            motiveringUtbildningsalternativ: 'epi:motiveringUtbildningsalternativ',
            utbildningsforutsattning: 'epi:utbildningsforutsattning',
            villkor: 'epi:villkor',
            inkomst: 'epi:inkomst',
            avserSokaCsn: 'epi:avserSokaCsn',
            studiemedelsberattigadUtbildning: 'epi:studiemedelsberattigadUtbildning',
            ejCsnAnledning: 'epi:ejCsnAnledning',
            utbildningRequired: 'epi:utbildningRequired',
            anordnare: 'epi:anordnare',
            antalTerminer: 'epi:antalTerminer',
            antalTerminerMin: 'epi:antalTerminerMin',
            antalTerminerMax: 'epi:antalTerminerMax',
            antalTerminerStudiestod: 'epi:antalTerminerStudiestod',
            koptUtbildning: 'epi:koptUtbildning',
            omfattning: 'epi:omfattning',
            studiestart: 'epi:studiestart',
            utbildningLangdEnhet: 'epi:utbildningLangdEnhet',
            utbildningsnamn: 'epi:utbildningsnamn',
            utbildningstyp: 'epi:utbildningstyp',

            dirtyFormPrompt: 'epi:dirtyFormPrompt',
        },
    },
    studiestodStudieintyg: {
        felmeddelanden: {
            systemError: 'epi:systemError',
            valideringError: 'epi:valideringError',
            fileSizeErrorMessage: 'epi:fileSizeErrorMessage',
            fileFormatErrorMessage: 'epi:fileFormatErrorMessage',
            fileNameErrorMessage: 'epi:fileNameErrorMessage',
        },
        laddauppstudieintyg: defaultLaddaUppStudieintygEpiContent,
        studieintygMall: {
            studieintygMallLink: 'epi:studieintygMallLink',
            studieintygMallLinkText: 'epi:studieintygMallLinkText',
        },
        infoCsnOmstallningsstod: {
            readMoreLink: 'epi:readMoreLink',
            readMoreLinkText: 'epi:readMoreLinkText',
        },
    },
    FunctionPage: null,
}
