import React from 'react'

interface Props {
    width?: string
    height?: string
}

const SomethingWentWrongIllustration = ({ width = '140', height = '140' }: Props) => (
    <svg width={width} height={height} viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M410.387 241.45C459.143 286.62 457.57 405.618 377.165 389.088L410.387 241.45Z" fill="#6222C3" />
        <path
            d="M342.405 317.064C349.562 357.39 343.483 361.739 337.651 363.189C333.852 364.126 327.773 363.843 320.156 339.286L314.996 320.246"
            fill="#A59EBC"
        />
        <path
            d="M325.864 311.919C321.711 356.258 314.077 359.069 307.556 358.875C303.315 358.751 297.024 356.718 296.141 328.749L296.247 307.305"
            fill="#A59EBC"
        />
        <mask id="mask0_635_290" type="luminance" maskUnits="userSpaceOnUse" x="314" y="317" width="32" height="47">
            <path
                d="M342.405 317.064C349.562 357.39 343.483 361.739 337.651 363.189C333.852 364.126 327.773 363.843 320.156 339.286L314.996 320.246L342.387 317.081L342.405 317.064Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_635_290)">
            <path
                d="M325.864 311.919C321.711 356.258 314.077 359.069 307.556 358.875"
                stroke="#716595"
                strokeWidth="1.25"
                strokeMiterlimit="10"
            />
        </g>
        <path
            d="M304.376 298.854C296.123 334.548 289.867 344.608 282.71 345.722C278.027 346.464 270.111 342.027 272.496 314.164L274.705 295.354"
            fill="#A59EBC"
        />
        <mask id="mask1_635_290" type="luminance" maskUnits="userSpaceOnUse" x="296" y="307" width="30" height="52">
            <path
                d="M325.864 311.919C321.711 356.258 314.077 359.069 307.556 358.875C303.315 358.751 297.024 356.718 296.141 328.749L296.247 307.305L325.882 311.901L325.864 311.919Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask1_635_290)">
            <path
                d="M304.376 298.854C296.124 334.548 289.868 344.608 282.711 345.722"
                stroke="#716595"
                strokeWidth="1.25"
                strokeMiterlimit="10"
            />
        </g>
        <path
            d="M281.014 290.262C273.026 322.173 266.382 329.987 258.217 328.926C254.577 328.467 244.964 327.247 249.965 288.229"
            fill="#A59EBC"
        />
        <mask id="mask2_635_290" type="luminance" maskUnits="userSpaceOnUse" x="272" y="295" width="33" height="51">
            <path
                d="M304.377 298.854C296.124 334.548 289.868 344.608 282.711 345.721C278.028 346.464 270.112 342.026 272.497 314.164L274.706 295.354L304.377 298.854Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask2_635_290)">
            <path
                d="M281.015 290.262C273.027 322.173 266.383 329.987 258.218 328.926"
                stroke="#716595"
                strokeWidth="1.25"
                strokeMiterlimit="10"
            />
        </g>
        <path
            d="M258.978 257.591C395.243 219.828 372.571 239.435 428.519 298.872L450.396 353.076C414.382 374.627 392.451 374.91 382.696 373.266C362.339 369.836 346.046 355.074 335.725 331.508L258.995 257.591H258.978Z"
            fill="#DBD8E4"
        />
        <path d="M338.34 306.438L326.412 270.426L296.229 276.472L302.008 291.376L338.34 306.438Z" fill="#A59EBC" />
        <path d="M326.412 270.426L296.229 276.472" stroke="#716595" strokeWidth="1.25" strokeMiterlimit="10" />
        <path d="M308.033 317.064L118.188 205.526L139.376 191.506L340.867 292.189L308.033 317.064Z" fill="#716595" />
        <path
            d="M129.073 326.257C200.359 326.257 258.147 268.444 258.147 197.128C258.147 125.813 200.359 68 129.073 68C57.7881 68 0 125.813 0 197.128C0 268.444 57.7881 326.257 129.073 326.257Z"
            fill="#6222C3"
        />
        <path
            d="M129.074 304.706C188.462 304.706 236.605 256.542 236.605 197.128C236.605 137.715 188.462 89.5508 129.074 89.5508C69.6856 89.5508 21.542 137.715 21.542 197.128C21.542 256.542 69.6856 304.706 129.074 304.706Z"
            fill="#D4C1EE"
        />
        <mask id="mask3_635_290" type="luminance" maskUnits="userSpaceOnUse" x="21" y="89" width="216" height="216">
            <path
                d="M129.074 304.706C188.462 304.706 236.605 256.542 236.605 197.128C236.605 137.715 188.462 89.5508 129.074 89.5508C69.6856 89.5508 21.542 137.715 21.542 197.128C21.542 256.542 69.6856 304.706 129.074 304.706Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask3_635_290)">
            <path d="M6.99854 196.068C85.6901 269.242 167.068 275.005 251.167 196.068H6.99854Z" fill="white" />
            <path d="M251.167 196.068C215.223 152.86 98.0956 97.8955 6.99854 196.068H251.167Z" fill="white" />
            <path
                d="M138.263 141.634C105.659 136.807 71.3939 170.698 76.4833 205.332C80.7245 234.29 111.703 258.475 142.098 251.174C168.164 244.898 184.015 217.831 182 192.337C180.074 167.834 161.66 145.081 138.263 141.616V141.634Z"
                fill="#089188"
            />
            <path
                d="M129.427 219.546C117.234 219.546 107.338 209.645 107.338 197.447C107.338 185.248 117.234 175.348 129.427 175.348C141.621 175.348 151.535 185.248 151.535 197.447C151.535 209.645 141.638 219.546 129.427 219.546Z"
                fill="#6222C3"
            />
        </g>
        <path
            d="M166.484 122.204C192.338 135.128 208.578 159.613 212.095 186.274"
            stroke="#EFF1F9"
            strokeWidth="1.25"
            strokeMiterlimit="10"
        />
        <path
            d="M448.275 432.738L500 281.812L410.387 241.432C424.065 329.174 446.614 389.672 377.165 389.071L448.275 432.738Z"
            fill="#089188"
        />
        <path
            d="M363.611 299.438C358.027 298.041 282.657 270.02 254.683 269.295C250.177 269.189 246.077 271.858 244.204 275.96C242.26 280.238 243.091 285.294 246.413 288.6C261.681 303.804 307.451 320.193 349.597 337.041"
            fill="#DBD8E4"
        />
        <path
            d="M274.953 282.89C272.779 281.14 270.022 279.548 266.894 278.329C257.882 274.846 249.117 275.8 247.314 280.468C245.512 285.135 251.343 291.747 260.356 295.23C263.325 296.379 266.276 297.051 268.944 297.263L274.953 282.89Z"
            stroke="#716595"
            strokeWidth="1.25"
            strokeMiterlimit="10"
        />
        <path d="M377.165 372.01C359.37 367.025 345.073 352.882 335.708 331.49" stroke="#948BAF" strokeWidth="1.25" strokeMiterlimit="10" />
        <mask id="mask4_635_290" type="luminance" maskUnits="userSpaceOnUse" x="248" y="288" width="116" height="54">
            <path
                d="M248.658 311C248.41 305.396 248.746 298.006 250.001 288.247L363.629 329.033C362.074 335.22 357.462 339.057 350.976 341.196L248.658 311.018V311Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask4_635_290)">
            <path
                d="M246.431 288.618C261.699 303.822 307.468 320.211 349.615 337.059"
                stroke="#716595"
                strokeWidth="1.25"
                strokeMiterlimit="10"
            />
        </g>
        <path d="M354.25 260.033L326.43 268.883L343.834 323.639L371.655 314.788L354.25 260.033Z" fill="#DBD8E4" />
    </svg>
)

export default SomethingWentWrongIllustration
