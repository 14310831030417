import { raknesnurrorApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { EkonomisktStudiestodApiResponseModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { useState } from 'react'

const useBeraknaKompletterandeStudiestod = () => {
    const [apiResponse, setApiResponse] = useState<ApiResponseModel<EkonomisktStudiestodApiResponseModel[]>>()

    const beraknaKompletterandeStudiestod = async (salary: number) => {
        const resp = await apiRequestTryCatchWrapper(
            'beraknaKompletterandeStudiestod',
            raknesnurrorApi.raknesnurrorEkonomisktStudiestodCreate,
            {
                salary,
            }
        )

        setApiResponse(resp)
    }
    return { beraknaKompletterandeStudiestod, apiResponse }
}

export default useBeraknaKompletterandeStudiestod
