import Form from '@local/src/Components/Form'
import SubmitResponse from '@local/src/Components/SubmitResponse/SubmitResponse'
import usePickEpiContentWithFallback from '@local/src/Hooks/usePickEpiContentWithFallback'
import { Alert, AlertTitle, FormHelperText, Stack, Typography } from '@mui/material'
import { useDirtyCheckPrompt } from '@trr/react-use-dirty-check-prompt'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useGTMTracking from '@local/src/Hooks/useGTMTracking'

import { DinBakgrund, MalMedStudierna, SubmitSaveButton, Utbildningar, Villkor } from './Components'
import { usePostStudiestodAnsokan } from './Hooks/usePostStudiestodAnsokan'
import { useStudiestodAnsokanForm } from './Hooks/useStudiestodAnsokanForm'
import { StudiestodAnsokanFormData } from './Models/StudiestodAnsokanForm.types'

interface Props {
    ansokan: StudiestodAnsokanFormData
    isEdit: boolean
    isPublicerad?: boolean
    isRedigerbar?: boolean
    ansokanId?: string
    isEjAktuell?: boolean
    ejAktuellOrsak?: string
    studiestodNummer?: number
}

const StudiestodAnsokanForm = ({
    ansokan,
    ansokanId,
    isEdit,
    isPublicerad = false,
    isRedigerbar = true,
    isEjAktuell = false,
    ejAktuellOrsak,
    studiestodNummer,
}: Props) => {
    const { studiestodForm: studiestodFormContent } = usePickEpiContentWithFallback()
    const { apiResponse, publishStudiestodAnsokan, saveStudiestodAnsokan, redirectToOverview } = usePostStudiestodAnsokan(isEdit, ansokanId)

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokanForm' })
    const history = useHistory()
    const formMethods = useStudiestodAnsokanForm(ansokan, isRedigerbar, studiestodFormContent)

    const link = studiestodFormContent.submitSaveButton.mittTrrSubmitButtonLink

    const {
        handleSubmit,
        reset,
        formState: { isDirty, errors, dirtyFields },
        watch,
    } = formMethods
    const { isReadonly, shouldPublish } = watch()

    const isReallyDraft = isDirty && Object.keys(dirtyFields).length > 0
    const { routerPrompt: RenderUnsavedChangesPrompt } = useDirtyCheckPrompt(
        isReallyDraft,
        studiestodFormContent.valideringsmeddelanden.dirtyFormPrompt
    )

    useEffect(() => {
        if (redirectToOverview) {
            history.push(link)
        }
    }, [history, link, redirectToOverview])

    const { trackClick } = useGTMTracking()

    const defaultTrackClickLabel = `StudiestödsansökanForm.${shouldPublish ? 'Publish' : 'Save'}`

    const onSubmit = async (data: StudiestodAnsokanFormData) => {
        const resp = data.shouldPublish ? await publishStudiestodAnsokan(data) : await saveStudiestodAnsokan(data)
        if (resp.isSuccess) {
            reset(data)

            trackClick('button', { label: defaultTrackClickLabel })
        }
    }

    const onInvalid = () => {
        trackClick('button', { label: `${defaultTrackClickLabel}.ValidationErrors` })
    }

    const ansokanNamn = studiestodNummer ? `#${studiestodNummer} - ${ansokan?.ansokanNamn}` : ansokan?.ansokanNamn
    const hasFormErrors = Object.keys(errors).length > 0

    return (
        <Stack spacing={4} maxWidth={(theme) => theme.breakpoints.values.md}>
            {isEjAktuell && (
                <Alert data-testid={'ejaktuell-alert'} severity="warning">
                    <AlertTitle>{t('ejAktuellAlert_Title')}</AlertTitle>
                    {ejAktuellOrsak}
                </Alert>
            )}
            <Typography variant="h6">{ansokanNamn}</Typography>
            <FormProvider {...formMethods}>
                <Form onSubmit={handleSubmit(onSubmit, onInvalid)} data-testid="studiestod-ansokan-form">
                    <Stack spacing={3}>
                        <MalMedStudierna content={studiestodFormContent.malMedStudierna} />
                        <Utbildningar
                            content={studiestodFormContent.utbildningar}
                            validationContent={studiestodFormContent.valideringsmeddelanden}
                        />
                        <DinBakgrund content={studiestodFormContent.dinBakgrund} />
                        <Villkor content={studiestodFormContent.villkor} />
                        {!isReadonly && (
                            <SubmitSaveButton
                                isPublicerad={isPublicerad}
                                isRedigerbar={isRedigerbar}
                                backToAnsokanListLink={link}
                                content={studiestodFormContent.submitSaveButton}
                                isEdit={isEdit}
                            />
                        )}
                        {hasFormErrors && (
                            <FormHelperText sx={{ fontSize: 16, mt: 2 }} error data-testid={'submit-button-error'}>
                                {t('formNotValid')}
                            </FormHelperText>
                        )}
                    </Stack>
                </Form>
                {!shouldPublish && <SubmitResponse apiResponse={apiResponse} successMessage="Din ansökan har sparats" />}

                {RenderUnsavedChangesPrompt}
            </FormProvider>
        </Stack>
    )
}

export default StudiestodAnsokanForm
