import { ReactQueryKey, studiestodKlientApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { userIsLoggedIn, getLoggedInUserKlientId } from '@local/src/Utils/Helpers/userHelper'
import { useQuery } from '@tanstack/react-query'

const useGetStudiestodAnsokanList = (ejAktuell?: boolean) => {
    const queryKey = [ReactQueryKey.getStudiestodList, getLoggedInUserKlientId(), ejAktuell]

    const { data: studiestodList, ...query } = useQuery({
        queryKey: queryKey,
        queryFn: () =>
            apiGetTryCatchWrapper('useGetStudiestodAnsokanList', studiestodKlientApi.klientAnsokanDetail, {
                klientId: getLoggedInUserKlientId(),
                ejAktuell: ejAktuell,
            }),
        placeholderData: [],
        keepPreviousData: true,
        enabled: userIsLoggedIn(),
    })

    return { studiestodList, ...query }
}

export default useGetStudiestodAnsokanList
