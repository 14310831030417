export const storageKeys = {
    newAnsokanSubmitted: 'newAnsokanSubmitted',
}

export const appShellKey = {
    studiestodRaknesnurraKey: 'studiestod-raknesnurra',
    studiestodStudieintygKey: 'studieintyg-ny',
    studiestodListKey: 'ditt-studiestod-ny',
    studiestodFormKey: 'ansok-om-studiestod-ny',
}
