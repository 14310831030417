import { CalculationResult } from '@local/src/Features/KompletterandeStudiestodRaknesnurraForm/Components'
import {
    AwaitingInputIllustration,
    SomethingWentWrongIllustration,
} from '@local/src/Features/KompletterandeStudiestodRaknesnurraForm/Components/Illustrations'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { EkonomisktStudiestodApiResponseModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    result?: EkonomisktStudiestodApiResponseModel
    apiResponse?: ApiResponseModel<EkonomisktStudiestodApiResponseModel[]>
}

const Results = ({ result, apiResponse }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'kompletterandeStudiestodRaknesnurraForm' })

    const hasResults = apiResponse?.isSuccess && Boolean(result)

    if (apiResponse?.hasError)
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                borderRadius={2}
                spacing={1}
                flex={1}
                bgcolor={(theme) => theme.palette.surface.purple}
            >
                <Stack justifyContent="center" alignItems="center" spacing={2} p={5}>
                    <SomethingWentWrongIllustration />
                    <Typography color={(theme) => theme.palette.text.secondary} textAlign="center">
                        {t('formErrors.submitError')}
                    </Typography>
                </Stack>
            </Stack>
        )

    return (
        <Stack spacing={1} flex={1}>
            <Stack justifyContent="center" alignItems="center" borderRadius={2} bgcolor={(theme) => theme.palette.surface.purple}>
                {hasResults ? (
                    <CalculationResult result={result} />
                ) : (
                    <Stack justifyContent="center" alignItems="center" spacing={2} p={5}>
                        <AwaitingInputIllustration />
                        <Typography color={(theme) => theme.palette.text.secondary} textAlign="center">
                            {t('awaitingInputText')}
                        </Typography>
                    </Stack>
                )}
            </Stack>

            {hasResults && (
                <Typography whiteSpace="pre-line" variant="caption">
                    {t('resultsExplanationText')}
                </Typography>
            )}
        </Stack>
    )
}

export default Results
