import { PageWrapper } from '@local/Components/PageWrapper'
import { StudiestodListApiModel } from '@local/Services/SwaggerApi/data-contracts'
import useGetStudiestodAnsokanList from '@local/src/Hooks/API/useGetStudiestodAnsokanList'
import { Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { AnsokanItemCard } from './Components'

const EjAktuellStudiestodAnsokanList = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokanList' })

    const { studiestodList, isError, isFetching, isFetchedAfterMount } = useGetStudiestodAnsokanList(true)
    const isLoading = !isFetchedAfterMount && isFetching
    const hasAnsokanItems = !isLoading && studiestodList && studiestodList.length > 0

    if (!hasAnsokanItems) return null

    return (
        <PageWrapper
            isLoading={isLoading}
            errorLoadingData={isError}
            errorText={t('errors.fetchFailedStudiestod')}
            testSelector="ej-aktuell-studiestod-list-wrapper"
            headerText={t('list', { context: 'ejAktuellTitle' })}
        >
            <Stack spacing={3}>
                {studiestodList.map((ansokan: StudiestodListApiModel) => (
                    <AnsokanItemCard key={ansokan.id} ansokan={ansokan} isEjAktuell />
                ))}
            </Stack>
        </PageWrapper>
    )
}

export default EjAktuellStudiestodAnsokanList
