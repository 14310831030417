/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { StudieintygListApiModel, StudiestodApiModel, TrrProblemDetails } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Studiestod<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Studiestod
     * @name ArendeAnsokanV2Detail
     * @request GET:/studiestod/arende/{arendeId}/ansokan/{studiestodId}/v2
     * @secure
     * @response `200` `StudiestodApiModel` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanV2Detail = (arendeId: string, studiestodId: string, params: RequestParams = {}) =>
        this.request<StudiestodApiModel, TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${studiestodId}/v2`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags Studiestod
     * @name ArendeAnsokanDetail
     * @request GET:/studiestod/arende/{arendeId}/ansokan/{studiestodId}
     * @secure
     * @response `200` `StudiestodApiModel` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanDetail = (arendeId: string, studiestodId: string, params: RequestParams = {}) =>
        this.request<StudiestodApiModel, TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${studiestodId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags Studiestod
     * @name ArendeAnsokanDocumentsDetail
     * @request GET:/studiestod/arende/{arendeId}/ansokan/{ansokanId}/documents
     * @secure
     * @response `200` `(StudieintygListApiModel)[]` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanDocumentsDetail = (arendeId: string, ansokanId: string, params: RequestParams = {}) =>
        this.request<StudieintygListApiModel[], TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${ansokanId}/documents`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags Studiestod
     * @name ArendeAnsokanDocumentDetail
     * @request GET:/studiestod/arende/{arendeId}/ansokan/{ansokanId}/document/{documentId}
     * @secure
     * @response `200` `void` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    arendeAnsokanDocumentDetail = (arendeId: string, ansokanId: string, documentId: string, params: RequestParams = {}) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/arende/${arendeId}/ansokan/${ansokanId}/document/${documentId}`,
            method: 'GET',
            secure: true,
            ...params,
        })
}
