import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { TrrValidationProblemDetails } from '@local/src/Services/SwaggerApi/data-contracts'
import { mapToTrrValidationErrorField } from '@local/src/Services/apiRequestHelper'
import { fetchRequest } from '@local/src/Utils/Network'
import { getOIDCUser } from '@trr/app-shell-communication'

export const uploadStudieintyg = async (ansokanId: string, data: FormData): Promise<ApiResponseModel<void>> => {
    const openIdCUser = getOIDCUser()
    const id_token = openIdCUser?.id_token || ''
    const klientId = openIdCUser?.profile?.sub || ''

    const response: Response = await fetchRequest({
        body: data,
        multipart: true,
        method: 'PATCH',
        id_token,
        requestUrl: `/studiestod/klient/${klientId}/ansokan/${ansokanId}/studieintyg`,
    })

    if (response.ok)
        return {
            hasError: false,
            isSuccess: true,
        }

    const problem = (await response.json()) as TrrValidationProblemDetails
    const hasFluentValidationErrors = (problem?.validationErrors ?? []).length > 0
    const errors = hasFluentValidationErrors ? problem?.validationErrors : mapToTrrValidationErrorField(problem?.errors)
    return {
        hasError: true,
        isSuccess: false,
        error: { errorMessage: problem?.title, errorType: problem?.errorType, validationErrors: errors, status: problem?.status },
    }
}
