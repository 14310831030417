import { Results } from '@local/src/Features/KompletterandeStudiestodRaknesnurraForm/Components'
import { Button, FormControl, InputAdornment, Stack, TextField } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, FormProvider, LiteralUnion, RegisterOptions, useForm } from 'react-hook-form'
import { Dropdown } from '@local/src/Components/FormComponents'
import { NumericFormat } from 'react-number-format'
import { isNil } from 'ramda'
import useGTMTracking from '@local/src/Hooks/useGTMTracking'
import { useIsFeatureEnabled } from '@trr/app-shell-data'

import useBeraknaKompletterandeStudiestod from './Hooks/useBeraknaKompletterandeStudiestod'

interface FormInputs {
    manadsLon: number | null
    studietakt: number
}
const KompletterandeStudiestodRaknesnurraForm = () => {
    const isNumericFormatTextFieldEnabled = useIsFeatureEnabled('Studiestod-Frontend_numericFormatTextField_temp_240911')

    const { t } = useTranslation('translation', { keyPrefix: 'kompletterandeStudiestodRaknesnurraForm' })
    const studietaktSelectOptions = t('studietaktSelectOptions', { returnObjects: true })

    const formMethods = useForm<FormInputs>({
        defaultValues: {
            manadsLon: null,
            studietakt: 100,
        },
        reValidateMode: 'onSubmit',
    })
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        control,
    } = formMethods

    const { studietakt } = watch()

    const { beraknaKompletterandeStudiestod, apiResponse } = useBeraknaKompletterandeStudiestod()

    const { trackClick } = useGTMTracking()
    const { t: tGtm } = useTranslation('translation', { keyPrefix: 'gtmTracking.raknesnurra' })

    const handleOnClick = async (formData: FormInputs) => {
        if (!formData.manadsLon) return
        await beraknaKompletterandeStudiestod(formData.manadsLon)
        trackClick('button', { label: tGtm('berakna_kompletterandeStudiestod') })
    }

    const selectedStudietaktResult = apiResponse?.response?.find((item) => item.studietakt === studietakt)

    const getErrorMessage = (error: LiteralUnion<keyof RegisterOptions, string>) => {
        switch (error) {
            case 'required':
                return t('formErrors.manadsLonRequired')
            case 'min':
                return t('formErrors.manadsLonMin')
            case 'max':
                return t('formErrors.manadsLonMax')
            default:
                return ''
        }
    }

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(handleOnClick)}>
                <Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5}>
                        <Stack spacing={5} flex={1}>
                            <FormControl fullWidth>
                                {isNumericFormatTextFieldEnabled ? (
                                    <Controller
                                        name="manadsLon"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <NumericFormat
                                                value={field.value}
                                                onValueChange={(values) => {
                                                    field.onChange(values.floatValue)
                                                }}
                                                customInput={TextField}
                                                label={t('textFieldLabel')}
                                                error={Boolean(errors?.manadsLon)}
                                                helperText={getErrorMessage(errors?.manadsLon?.type)}
                                                thousandSeparator=" "
                                                allowNegative={false}
                                                isAllowed={({ floatValue }) =>
                                                    (floatValue <= 999999 && floatValue > 0) || isNil(floatValue)
                                                }
                                                decimalScale={0}
                                                inputMode="numeric"
                                                InputProps={{ endAdornment: <InputAdornment position="end">kr</InputAdornment> }}
                                            />
                                        )}
                                    />
                                ) : (
                                    <TextField
                                        label={t('textFieldLabel')}
                                        type="number"
                                        error={Boolean(errors?.manadsLon)}
                                        helperText={getErrorMessage(errors?.manadsLon?.type)}
                                        {...register('manadsLon', { valueAsNumber: true, required: true, min: 1, max: 999_999 })}
                                    />
                                )}
                            </FormControl>

                            <Dropdown label={t('selectLabel')} name="studietakt" selectOptions={studietaktSelectOptions} />

                            <Button type="submit" data-trackable="false">
                                {t('buttonLabel')}
                            </Button>
                        </Stack>

                        <Results result={selectedStudietaktResult} apiResponse={apiResponse} />
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    )
}

export default KompletterandeStudiestodRaknesnurraForm
