import { ApiConfig } from '@local/Services/SwaggerApi/http-client'
import getConfig from '@local/Utils/getConfig'
import { Rakneparametrar } from '@local/src/Services/SwaggerApi/Rakneparametrar'
import { removeDuplicateSlashes } from '@local/src/Utils/Helpers/urlHelper'
import { getLoggedInUserIdToken } from '@local/src/Utils/Helpers/userHelper'
import { Raknesnurror } from '@local/src/Services/SwaggerApi/Raknesnurror'

import { Studiestod } from './SwaggerApi/Studiestod'
import { StudiestodKlient } from './SwaggerApi/StudiestodKlient'

export const ReactQueryKey = {
    getStudiestodList: 'getStudiestodList',
    getStudiestodsansokanById: 'getStudiestodsansokanById',
} as const

export const getAuthHeaders = (): HeadersInit => {
    const id_token = getLoggedInUserIdToken()
    return {
        Authorization: `Bearer ${id_token}`,
        'Accept-Language': 'sv',
    }
}

export const getBaseRequestParams = () => ({
    headers: getAuthHeaders(),
})

const apiConfig: ApiConfig = {
    baseUrl: removeDuplicateSlashes(`${getConfig().API_URL}`),
}
const apiPublicWebConfig: ApiConfig = {
    baseUrl: removeDuplicateSlashes(`${getConfig().PUBLIC_WEB_API_URL}`),
}

export const studiestodKlientApi = new StudiestodKlient(apiConfig)
export const studiestodApi = new Studiestod(apiConfig)
export const rakneparametrarApi = new Rakneparametrar(apiPublicWebConfig)
export const raknesnurrorApi = new Raknesnurror(apiPublicWebConfig)
