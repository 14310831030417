import useGTMTracking from '@local/src/Hooks/useGTMTracking'
import { EkonomisktStudiestodApiResponseModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { formatCurrencySEK } from '@local/src/Utils/Helpers'
import { Chip, Stack, SxProps, Theme, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const chipSelectedStyle: SxProps<Theme> = {
    bgcolor: (theme) => theme.palette.primary.light,
    border: 'none',
    color: (theme) => theme.palette.primary.dark,
}

interface Props {
    result: EkonomisktStudiestodApiResponseModel
}

const CalculationResult = ({ result }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'kompletterandeStudiestodRaknesnurraForm' })

    const { trackClick } = useGTMTracking()
    const { t: tGtm } = useTranslation('translation', { keyPrefix: 'gtmTracking.raknesnurra' })

    const [selectedPeriod, setSelectedPeriod] = useState<'perVecka' | 'perManad'>('perVecka')

    const handlePerVeckaClick = () => {
        setSelectedPeriod('perVecka')
        trackClick('button', { label: tGtm('perVecka_kompletterandeStudiestod') })
    }

    const handlePerManadClick = () => {
        setSelectedPeriod('perManad')
        trackClick('button', { label: tGtm('perManad_kompletterandeStudiestod') })
    }
    const isVeckaSelected = selectedPeriod === 'perVecka'

    const total = formatCurrencySEK(isVeckaSelected ? result?.totalVecka : result?.totalManad)
    const oss = formatCurrencySEK(isVeckaSelected ? result?.ossPerVecka : result?.ossPerManad)
    const kss = formatCurrencySEK(isVeckaSelected ? result?.kssPerVecka : result?.kssPerManad)

    return (
        <Stack p={4} spacing={4}>
            <Stack px={5} direction="row" justifyContent="space-between" spacing={2}>
                <Chip
                    label={t('buttonTextPerVecka')}
                    onClick={handlePerVeckaClick}
                    sx={selectedPeriod === 'perVecka' && chipSelectedStyle}
                />
                <Chip
                    label={t('buttonTextPerManad')}
                    onClick={handlePerManadClick}
                    sx={selectedPeriod === 'perManad' && chipSelectedStyle}
                />
            </Stack>
            <Stack spacing={2}>
                <Typography textAlign="center">{t('preliminartBelopp')}</Typography>
                <Typography textAlign="center" variant="h2" color={(theme) => theme.palette.primary.main}>{`${total}*`}</Typography>
                <Typography textAlign="center">{t('period', { context: selectedPeriod })}</Typography>
            </Stack>

            <Stack spacing={2}>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <Typography flex={3} variant="body2" sx={{ wordBreak: 'break-word' }}>
                        {t('ossText')}
                    </Typography>
                    <Typography
                        flex={1}
                        align="right"
                        fontWeight="bold"
                        color={(theme) => theme.palette.primary.main}
                    >{`${oss}`}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <Typography flex={3} variant="body2" sx={{ wordBreak: 'break-word' }}>
                        {t('kssText')}
                    </Typography>
                    <Typography
                        flex={1}
                        align="right"
                        fontWeight="bold"
                        color={(theme) => theme.palette.primary.main}
                    >{`${kss}`}</Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default CalculationResult
