import { yupResolver } from '@hookform/resolvers/yup'
import { useScrollToError } from '@local/src/Hooks'
import { useForm } from 'react-hook-form'

import { StudiestodAnsokanFormData } from '../Models/StudiestodAnsokanForm.types'
import { getStudiestodAnsokanFormSchema } from '../Schemas/StudiestodAnsokanFormSchema'
import { StudiestodAnsokanFormEpiContent } from '../StudiestodAnsokanForm.content'

export const useStudiestodAnsokanForm = (
    ansokan: StudiestodAnsokanFormData,
    isRedigerbar: boolean,
    epiContent: StudiestodAnsokanFormEpiContent
) => {
    const methods = useForm<StudiestodAnsokanFormData>({
        values: {
            ...ansokan,
            isReadonly: !isRedigerbar,
            shouldPublish: false,
        },
        resolver: (data: StudiestodAnsokanFormData, context, options) => {
            const currentSchema = getStudiestodAnsokanFormSchema(data.shouldPublish, epiContent.valideringsmeddelanden)
            return yupResolver(currentSchema)(data, context, options)
        },
        mode: 'onChange',
        shouldFocusError: true,
    })

    const {
        formState: { errors, isSubmitted },
    } = methods

    useScrollToError(errors, isSubmitted)

    return methods
}
